@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes standout-animation {
    0% {
        box-shadow: 0 0 0 0 #1e3a8a;
    }
    100% {
        box-shadow: 0 0 10px 1px #1e3a8ab3;
    }
}

.standout-div {
    animation: standout-animation 1s;
    box-shadow: 0 0 10px 1px #1e3a8ab3;
}
